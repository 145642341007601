<template>
  <router-view/>
</template>

<script>
  

</script>


<style lang="scss">

</style>
