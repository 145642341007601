export default {
  "message": {
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo Welt"])},
    "cardHeadline1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschrift"])},
    "cardBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier steht ein längerer Text"])}
  },
  "navbarNav": {
    "item": {
      "1": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/caffe-te/caffe"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAFFE DELIZIO"])}
      },
      "2": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/macchine/macchina-per-capsule"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MACCHINE DEL CAFFÈ"])}
      },
      "3": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/sostenibilita"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sostenibilità"])}
      },
      "4": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/programma-di-bonus"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROGRAMMA DI BONUS"])}
      },
      "5": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/ricetta"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricette"])}
      },
      "6": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/checkout/cart"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrello"])}
      }
    },
    "subMenuToggler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])}
  },
  "heroHeader": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRINKE EIN GANZES JAHR GRATIS DELIZIO KAFFEE! DE"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige uns, dass Kaffee Deine grosse Leidenschaft ist und bewirb Dich zum Coffee-Lover #1."])},
    "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JETZT TEILNEHMEN"])},
    "socialShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share this contest with your friends:"])},
    "socialIcons": {
      "1": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://google.com"])}
      },
      "2": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://google.com"])}
      },
      "3": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://google.com"])}
      }
    },
    "img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/assets/image/header.png"])},
    "imgAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header alt tag"])}
  },
  "textBlock": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAS GEWINNSPIEL - COFFEE-LOVER #1"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this paragraph to explain the purpose of the contest, the impact that the winning entries will have, and the overall benefits for participants, beyond just the prizes. But mention the wonderful prizes too!"])},
    "paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this space to provide important information about the contest's requirements, such as eligibility and deadline criteria (e.g. Enter before 25 Dec 2019; this contest is available only for customers located in the continental US etc)."])}
  },
  "priceBox": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Preise"])},
    "subheadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinne einen Jahresvorrat an Delizio Kaffee-Kapseln und viele weitere, tolle Preise"])},
    "item": {
      "1": {
        "img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/assets/image/Lay_6000Maschinen_1Gewinnbild.jpg"])},
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptpreis"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinne einen Jahresvorrat an Kaffeekapseln* inkl. Delizio Maschine"])}
      },
      "2": {
        "img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/assets/image/Lay_6000Maschinen_2Gewinnbild.jpg"])},
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.-10. Platz"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinne einen Monatsvorrat an Delizio-Kaffee inkl. Delizio Maschine"])}
      },
      "3": {
        "img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/assets/image/Lay_6000Maschinen_3Gewinnbild.jpg"])},
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.-500. Platz"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinne eine Gratis Kaffeemaschine von Delizio"])}
      }
    }
  },
  "stepBox": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie werde ich zum Coffee-Lover #1"])},
    "subheadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beweise uns, dass Du der absolute Kaffee-Crack bist"])},
    "item": {
      "1": {
        "img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/assets/image/icons/icon-formular.png"])},
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehe zur Landingpage"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Text - Lorem ipsum dolor sit amet, consectetur"])}
      },
      "2": {
        "img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/assets/image/icons/icon-fragen.png"])},
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei Kreativ"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Text - Lorem ipsum dolor sit amet, consectetur"])}
      },
      "3": {
        "img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/assets/image/icons/icon-beschreibung.png"])},
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewirb Dich"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Text - Lorem ipsum dolor sit amet, consectetur"])}
      },
      "4": {
        "img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/assets/image/icons/icon-abwarten.png"])},
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzähle es weiter"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Text - Lorem ipsum dolor sit amet, consectetur"])}
      }
    }
  },
  "competitionFormular": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Chance auf 1 Jahr lang gratis Kaffee"])},
    "name": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
    },
    "firstname": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])}
    },
    "email": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email-Adresse"])}
    },
    "howDrinkCoffee": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was für ein Kaffee-Charakter bist du?"])},
      "option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich trinke Kaffee nur am morgen"])},
      "option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ich Lust dazu habe"])},
      "option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin ein Geniesser"])},
      "option4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaffee gehört zur Routine"])},
      "option5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Kaffee geht gar nichts"])}
    },
    "whenDrinkCoffee": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie trinkst du deinen Kaffee am liebsten?"])},
      "option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ristretto"])},
      "option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espresso"])},
      "option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lungo"])},
      "option4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Americano"])},
      "option5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cappucino"])},
      "option6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Café Latte"])}
    },
    "whereDrinkCoffee": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist dir bei einer Kaffeemarke besonders wichtig? (mehrere Antworten möglich)"])},
      "option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
      "option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualität"])},
      "option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschmack"])},
      "option4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktisch"])},
      "option5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortimentsauswahl"])},
      "option6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit"])},
      "option7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote/Aktionen"])},
      "option8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachhaltigkeit"])}
    },
    "favoriteCoffee": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welches Kaffeesystem nutzt du aktuell?"])},
      "option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delizio Kapselsystem"])},
      "option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anderes Kapselsystem"])},
      "option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pads"])},
      "option4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollautomat"])},
      "option5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolbenmaschine"])},
      "option6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filterkaffee"])},
      "option7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bialetti"])},
      "option8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keins"])},
      "option9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anderes"])}
    },
    "message": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibe uns weshalb du das Delizio Jahrespaket gewinnen sollst:"])}
    },
    "uploadMedia": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video/Foto Upload (max. 50Mb)"])}
    },
    "checkTerms": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin mit den <a href='#terms-and-conditions' v-smooth-scroll>Teilnahme- und Datenschutzbestimmungen</a> einverstanden."])}
    },
    "enterResults": {
      "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versenden"])}
    }
  },
  "terms": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEILNAHME- UND DATENSCHUTZBESTIMMUNGEN"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."])}
  },
  "footer": {
    "newsletter": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEWSLETTER"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Mi iscrivo alla newsletter Delizio per essere sempre al corrente in merito ai nuovi prodotti, alle azioni e alle offerte speciali. Posso disdire in qualsiasi momento l'iscrizione alla newsletter."])},
      "list1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte speciali"])},
      "list2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconti esclusivi mensili"])},
      "list3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul mondo del caffè"])},
      "registerLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/iscrizione-newsletter"])},
      "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGISTRATI ORA"])},
      "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Avete comunque la possibilità di disdire la newsletter in qualsiasi momento. Maggiori informazioni sono disponibili nelle disposizioni in materia di <a href='https://www.delizio.ch/it/disposizioni-sulla-protezione-dei-dati' target='_blank' rel='noopener noreferrer'>protezione dei dati</a>."])}
    },
    "bonusProgram": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROGRAMMA DI BONUS"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approfittate di ulteriori vantaggi a ogni tazza di pura bontà e iscrivetevi al nostro programma di bonus. Potete beneficiare di esclusivi premi Delizio e di altre interessanti offerte."])},
      "registerLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://login.migros.ch/register?hl=it"])},
      "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si registri"])}
    },
    "products": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOSTRI PRODOTTI"])},
      "links": {
        "1": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/manuale"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuale"])}
        },
        "2": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/caffe-te/caffe"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caffe Delizio"])}
        },
        "3": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/macchine/macchina-per-capsule"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macchine del caffè"])}
        },
        "4": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/twin/capsule"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capsule di caffè Twin"])}
        },
        "5": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/macchine/accessori"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessori"])}
        }
      }
    },
    "contact": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTATTO"])},
      "links": {
        "1": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/conttato"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conttato"])}
        },
        "2": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/stampa"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stampa"])}
        }
      }
    },
    "information": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMAZIONI"])},
      "links": {
        "1": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/cg"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CG"])}
        },
        "2": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/faq"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])}
        },
        "3": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/sostenibilita"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sostenibilità"])}
        },
        "4": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/iscrizione-newsletter"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscrizione newsletter"])}
        },
        "5": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/disposizioni-sulla-protezione-dei-dati"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposizioni sulla protezione dei dati"])}
        },
        "6": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/colophon"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colophon"])}
        },
        "7": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delica.com/it"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produttrice Delica AG"])}
        }
      }
    },
    "social": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEGUICI SU"])},
      "links": {
        "1": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.facebook.com/delizio.ch"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])}
        },
        "2": {
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/channel/UCftPOTn1R5ZDC6A0Bkky74g"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube"])}
        }
      }
    },
    "payment": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MODALITÀ DI PAGAMENTO NELLO SHOP ONLINE DI DELIZIO"])}
    },
    "sale": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POSSIBILITÀ DI ACQUISTO"])}
    }
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld ist erforderlich."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine gültige E-Mail Adresse."])},
    "emailAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese E-Mail wurde bereitsverwendet."])}
  },
  "cookieCompliance": {
    "functional-cookies": {
      "cookie-setting--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essenziale"])},
      "cookie-setting--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cookie funzionali sono assolutamente necessari per la funzionalità del webshop. Questi cookie assegnano al browser un ID casuale e univoco per garantire un'esperienza d'acquisto fluida anche nel corso di più accessi al sito."])},
      "cookie--item": {
        "1": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie di sessione:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cookie di sessione salva i dati dei tuoi acquisti sulle varie pagine visualizzate ed è pertanto indispensabile per la tua personale esperienza d'acquisto."])}
        },
        "2": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sUniqueID:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cookie permette di mettere a disposizione dell'utente un promemoria della lista dei desideri durante più sessioni. Il promemoria rimane in tal modo disponibile anche per diverse sessioni del browser."])}
        },
        "3": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X-ua-device:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'assegnazione dei dispositivi aiuta lo shop a garantire la miglior visualizzazione possibile per le dimensioni del display attualmente attivo."])}
        },
        "4": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token CSRF:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cookie token CSRF contribuisce alla tua sicurezza. Nei moduli, aumenta la sicurezza da attacchi indesiderati degli hacker."])}
        },
        "5": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slt:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il token di login garantisce il riconoscimento degli utenti a ogni sessione. Il cookie non contiene dati personali, ma consente una personalizzazione durante più sessioni del browser."])}
        },
        "6": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cache:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cookie di esclusione cache permette agli utenti di leggere contenuti individuali indipendentemente dalla memoria cache."])}
        },
        "7": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test cookie:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cookie è utilizzato dal sito web per verificare se il browser dell'utente della pagina accetta i cookie."])}
        },
        "8": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Impostazioni:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cookie è utilizzato per salvare le impostazioni dei cookie dell'utente della pagina nel corso di più sessioni del browser."])}
        },
        "9": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acris_cookie_*:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cookie salva la pagina di provenienza e la prima pagina visitata dall'utente per un utilizzo successivo."])}
        },
        "10": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acris_cookie_first_activated:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva i cookie già accettati dall'utente per la prima volta."])}
        },
        "11": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token CSRF:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cookie token CSRF contribuisce alla tua sicurezza. Nei moduli, aumenta la sicurezza da attacchi indesiderati degli hacker."])}
        }
      }
    },
    "tracking-cookies": {
      "cookie-setting--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking"])},
      "cookie-setting--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I tracking cookie aiutano il gestore dello shop a raccogliere informazioni sul comportamento degli utenti sul sito web e ad analizzarle."])},
      "cookie--item": {
        "1": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Analytics:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Analytics è utilizzato per l'analisi del traffico dati del sito internet. In tal modo è possibile creare e leggere statistiche sulle attività del sito Internet."])}
        },
        "2": {
          "cookie--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Tag Manager:"])},
          "cookie--description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questi cookie ci consentono di trasmettere a Google Analytics i dati di analisi tramite Google Tag Manager e fungono da linker conversioni."])}
        }
      }
    },
    "global": {
      "switch--slider--active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
      "switch--slider--inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inattivo"])},
      "bigCookieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizziamo cookie e altre misure tecniche e trattiamo dati personali come il vostro indirizzo IP o le informazioni del browser per ottimizzare il nostro sito web e il nostro shop online e per migliorarlo costantemente. Se riceviamo il vostro consenso, trasmetteremo informazioni sull'utilizzo del sito web ai nostri partner per i social media, la pubblicità e le analisi. Nella nostra <a href='https://www.delizio.ch/it/disposizioni-sulla-protezione-dei-dati' target='_blank' rel='noopener noreferrer'>dichiarazione sulla protezione dei dati</a> sono disponibili informazioni dettagliate sul trattamento dei vostri dati personali, sullo scopo perseguito e sulle vostre possibilità di revoca. Potete dare il vostro consenso all'impiego di cookie nonché alle misure tecniche tramite i cluster nelle impostazioni cookie o accettare tutti i cookie e tutte le misure tecniche con «Accetta tutti i cookie e salva»."])},
      "cookie-permission--deny-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzeinstellungen"])},
      "cookie-permission--accept-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma la scelta"])},
      "cookie-permission--accept-all-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta tutti i cookie e salva"])},
      "cookie-permission--imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
      "cookie-permission--imprint-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.delizio.ch/it/colophon"])}
    }
  }
}