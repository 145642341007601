// import { createApp } from "vue";
import { createApp } from "vue/dist/vue.esm-bundler";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import VueSmoothScroll from "v-smooth-scroll";
import useVuelidate from "@vuelidate/core";
import SlideUpDown from "vue3-slide-up-down";
import { createGtm } from "@gtm-support/vue-gtm";
import { createHead } from "@vueuse/head";
import Tabs from 'vue3-tabs';

import "./assets/scss/_style.scss";

const app = createApp(App);
const head = createHead();

app.use(i18n);
app.use(router);
app.use(VueSmoothScroll);
app.use(useVuelidate);
app.use(Tabs);
app.use(
  createGtm({
    id: process.env.VUE_APP_GOOGLETAG,
    enabled: true,
    debug: false,
    vueRouter: router,
  })
);
app.use(head);

app.component("slide-up-down", SlideUpDown);

app.config.globalProperties.apiUrl = process.env.VUE_APP_API_URL;
app.config.globalProperties.hostedUrl = process.env.VUE_APP_HOSTED_URL;

app.config.compilerOptions.isCustomElement = (tag) => {
  let customElementArray = ["MachineInformation", "PriceBoxItem", "StepItem"];
  if (customElementArray.includes(tag)) return true;
};
app.mount("#app");
