import { createRouter, createWebHistory } from "vue-router";
import App from "../App";
import i18n from "../i18n";

function load(component) {
  // '@' is aliased to src/components
  return () => import(`../views/${component}.vue`);
}

const routes = [
  {
    path: "/:locale",
    component: App,
    children: [
      {
        path: "",
        name: "Home",
        component: load("Home"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    redirect() {
      return process.env.VUE_APP_I18N_LOCALE;
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const locale = to.params.locale; // 1
  const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(
    ","
  ); // 2
  if (!supported_locales.includes(locale))
    return next(process.env.VUE_APP_I18N_LOCALE); // 3
  if (i18n.locale !== locale) {
    // 4
    i18n.locale = locale;
    i18n.global.locale = locale;
    document.querySelector("html").setAttribute("lang", locale);
  }
  console.log(locale);
  return next(); // 5
});

export default router;
